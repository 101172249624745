import { AppDispatchNext, AppGetState } from 'actions/actions.typesNext'
import { fetchPartnerLinkApi } from 'api/system/fetchPartnerLinkApi'

export const fetchPartnerLinkAction =
  () => (dispatch: AppDispatchNext, getState: AppGetState) => {
    return dispatch({
      type: 'PARTNER_LINK',
      promise: () => fetchPartnerLinkApi(getState().systemReducer.query),
    })
  }
